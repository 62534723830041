/* Dashboard Layout Styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f3f4f6;
  color: #111827;
}

.dashboard {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.dashboard__header {
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

.dashboard__header-content {
  max-width: 1280px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard__title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #111827;
}

.dashboard__logout-btn {
  background-color: transparent;
  border: 1px solid #d1d5db;
  color: #4b5563;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.dashboard__logout-btn:hover {
  background-color: #f3f4f6;
}

.dashboard__logout-icon {
  margin-right: 0.5rem;
}

.dashboard__main {
  flex-grow: 1;
  padding: 2rem 1rem;
}

.dashboard__content {
  max-width: 1280px;
  margin: 0 auto;
}

.dashboard__footer {
  background-color: #ffffff;
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.1), 0 -1px 2px 0 rgba(0, 0, 0, 0.06);
  margin-top: 2rem;
}

.dashboard__footer-content {
  max-width: 1280px;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
  color: #6b7280;
  font-size: 0.875rem;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .dashboard__header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .dashboard__logout-btn {
    margin-top: 1rem;
  }
}
