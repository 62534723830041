.calendar__nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.calendar__nav-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  margin: 0 15px;
  background-color: #eff6ff;
  color: #2563eb;
}

.calendar__nav-btn:hover {
  background-color: #b8dcff;
}

.calendar__nav-btn--disabled {
  opacity: 0.5;
  cursor: default;
  background: none;
  color: #111827;
}

.calendar__nav-btn--disabled:hover {
  background: none;
}

.calendar__month {
  font-size: 16px;
  font-weight: 400;
  color: #111827;
}
