.calendar__weekdays {
  display: grid;
  grid-template-columns: repeat(7, 53px);
  gap: 4px;
  margin-bottom: 8px;
}

.calendar__weekday {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #6b7280;
}

.calendar__days {
  display: grid;
  grid-template-columns: repeat(7, 53px);
  gap: 4px;
}

.calendar__day {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background: transparent;
  color: #111827;
  position: relative;
  transition: background-color 0.3s;
  margin: 0 auto;
}

.calendar__day--event {
  background-color: #eff6ff;
  color: #2563eb;
  font-weight: 700;
}

.calendar__day--event:hover {
  background-color: #b8dcff;
}

.calendar__day--selected {
  cursor: default;
  background-color: #2563eb;
  color: white;
}

.calendar__day--selected:hover {
  cursor: default;
  background-color: #2563eb;
  color: white;
}

.calendar__day--today {
  font-weight: 600;
}

.calendar__day-dot {
  width: 4px;
  height: 4px;
  background-color: #2563eb;
  border-radius: 50%;
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%) translateY(-150%);
}

.calendar__day-dot--selected {
  width: 4px;
  height: 4px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%) translateY(-150%);
}

.calendar__day-dot--no-event {
  width: 4px;
  height: 4px;
  background-color: rgba(26, 26, 26, 0.5);
  border-radius: 50%;
  position: absolute;
  bottom: 2px;
  left: 50%;
  transform: translateX(-50%) translateY(-150%);
}

.calendar__day:disabled {
  cursor: default;
  color: rgba(26, 26, 26, 0.5);
}
