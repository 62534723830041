.calendar__timezone {
  margin-top: 16px;
  position: relative;
}

.calendar_timezone-title {
  font-weight: 700;
  font-size: 16px;
}

.calendar__timezone-btn-container {
  width: 100%;
}

.calendar__timezone-btn-container:hover {
  background-color: rgba(26, 26, 26, 0.1);
  border-radius: 20px;
}

.calendar__timezone-btn {
  width: 100%;
  padding: 8px;
  text-align: left;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #111827;
  transition: border-color 0.3s;
}

.calendar__timezone-btn:hover {
  border-color: #d1d5db;
}

.calendar__timezone-btn-text {
  font-weight: 400;
  font-size: 14px;
}

.calendar__timezone-selector {
  position: absolute;
  bottom: 60%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  max-height: 350px;
  width: 400px;
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.calendar__timezone-selector-head {
  background-color: #fff;
  width: 100%;
  height: 90px;
  padding-top: 15px;
  padding-inline: 12px;
  border-radius: 12px;
}

.calendar__timezone-selector-list-wrapper {
  max-height: 260px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 12px;
  margin-right: -12px;
}

.calendar__timezone-selector-list {
  padding-top: 10px;
}

.calendar__timezone-selector-list-region {
  font-weight: 700;
  padding: 8px 0;
  cursor: default;
  font-size: 14px;
  padding-inline: 12px;
}

.calendar__timezone-search {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.calendar__timezone-search input {
  outline: none;
  font-size: 14px;
  border-radius: 8px;
  width: 100%;
  border: 2px solid #e5e7eb;
  padding: 8px;
  padding-left: 12px;
}

.calendar__timezone-search input:focus {
  border: 2px solid #2563eb;
}

.calendar__timezone-format {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.calendar__timezone-format span {
  font-size: 12px;
  font-weight: 700;
  color: #000;
  cursor: default;
}

.calendar__timezone-format p {
  font-size: 12px;
  font-weight: 600;
  color: #6b7280;
  cursor: default;
}

.calendar__timezone-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5e7eb;
  transition: 0.4s;
  border-radius: 34px;
  transform: scaleY(-60%);
}

.slider:before {
  position: absolute;
  content: "";
  border: 1px solid #000;
  height: 33px;
  width: 20px;
  bottom: -6px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
  cursor: pointer;
}

input:checked + .slider {
  background-color: #e5e7eb;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

.calendar__timezone-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #111827;
  transition: background-color 0.3s;
  padding-inline: 20px;
}

.calendar__timezone-option:hover {
  background-color: #eff6ff;
}

.calendar__timezone-option--selected {
  background-color: #2563eb;
  color: white;
}

.calendar__timezone-option--selected:hover {
  background-color: #2563eb;
  color: white;
  cursor: default;
}

.calendar__timezone-selector-list-wrapper::-webkit-scrollbar {
  width: 4px;
}

.calendar__timezone-selector-list-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.calendar__timezone-selector-list-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.calendar__timezone-selector-list-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.calendar__timezone-selector-list-wrapper {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
