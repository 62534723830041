.calendar {
  font-family: "Montserrat", sans-serif;
  max-width: 750px;
  max-height: 600px;
  margin: 0 auto;
  padding: 25px;
  padding-right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.calendar__no-selected-date {
  font-family: "Montserrat", sans-serif;
  max-width: 450px;
  max-height: 600px;
  margin: 0 auto;
  padding: 25px;
  padding-right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.calendar__title {
  font-size: 20px;
  font-weight: 700;
  margin-left: 15px;
  margin-bottom: 24px;
  color: #111827;
}

.calendar__content {
  display: flex;
  gap: 24px;
}

.calendar__main {
  width: 54%;
}

.calendar__main--no-selected-date {
  width: 93%;
}
