.calendar__events {
  width: 50%;
  padding-right: 30px;
}

.calendar__events-title {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 50px;
  color: #111827;
}

.calendar__time-slots-wrapper {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 20px;
  margin-right: -20px;
}

.calendar__time-slots {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 1px;
  padding-bottom: 2px;
  padding-left: 10px;
}

.calendar__time-slot {
  width: 100%;
  height: 52px;
  padding: 12px;
  font-weight: 700;
  text-align: center;
  border: 2px solid transparent;
  box-shadow: 0 0 0 1px rgba(0, 105, 255, 0.5);
  border-radius: 4px;
  background-color: white;
  color: rgb(0, 105, 255);
  cursor: pointer;
  transition: border 0.3s, box-shadow 0.3s;
  font-size: 16px;
  box-sizing: border-box;
}

.calendar__time-slot:hover {
  border-color: rgb(0, 105, 255);
  box-shadow: none;
}

.calendar__time-slots-wrapper::-webkit-scrollbar {
  width: 4px;
}

.calendar__time-slots-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.calendar__time-slots-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.calendar__time-slots-wrapper::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.calendar__time-slots-wrapper {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
